// config/navItems.ts

export interface NavItem {
  titleKey: string; // Key for translation
  url: string;
}

export const routeLandingItems: NavItem[] = [
  { titleKey: "students", url: "/" },
  // { titleKey: "corporates", url: "/corporates" },
  { titleKey: "whoWeAre", url: "/about-us" },
  { titleKey: "news", url: "/news" },
];
