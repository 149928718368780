import { usePathname } from "next/navigation";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
  SheetClose,
  SheetTitle,
} from "@/components/ui/sheet";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { Separator } from "@/components/ui/separator";
import {
  Menu01Icon,
  Agreement01Icon,
  UserGroupIcon,
  NewsIcon,
  EarthIcon,
  Cancel01Icon,
  hugeiconsLicense,
} from "@hugeicons/react-pro";
import StudentIcon from "/public/student.svg?icon";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";
import LanguageSwitcherMobile from "./language-switcher-mobile";

hugeiconsLicense(
  "a32799ec963d4c4927c5b30ac44a6cc9RT0xNzQ4NDM0NDQ1Nzc5LFM9cHJvLFY9MSxQPUd1bXJvYWQsU1Q9NmM5ZjU5YTAsRVQ9ZDQ0Mjk4ODM=",
);

export default function Hamburger() {
  const pathName = usePathname();
  const locale = useLocale();

  function isNavItemActive(url: string) {
    if (url === "/") {
      return pathName === `/${locale}`;
    } else {
      return pathName === `/${locale}${url}`;
    }
  }

  const t = useTranslations("landing.routes");
  return (
    <Sheet>
      <SheetTrigger asChild>
        <button className="flex h-[38px] w-[38px] items-center justify-center rounded-[6px] border border-solid border-[#DADADA] bg-white lg:hidden">
          <Menu01Icon size={24} color="#0C1235" />
        </button>
      </SheetTrigger>
      <SheetContent className="flex flex-col justify-stretch gap-0 bg-[#0C1235] px-0 ">
        <VisuallyHidden.Root>
          <SheetTitle>Hamburger menu</SheetTitle>
        </VisuallyHidden.Root>
        <SheetHeader className="mt-2 flex w-full flex-row justify-end px-4">
          <SheetClose className="w-11 focus-visible:!outline-none">
            <div className="flex h-11 w-11 items-center justify-center rounded-[8px] bg-[#222D6B]">
              <Cancel01Icon size={24} color="#fff" />
            </div>
          </SheetClose>
        </SheetHeader>
        <Separator className="!mb-4 !mt-6 !bg-white !bg-opacity-35" />

        <SheetClose asChild>
          <Link className="flex items-center gap-2 px-4" href="/">
            <StudentIcon
              className="w-[28px]"
              stroke="#fff"
              strokeWidth={1.5}
              fill={isNavItemActive("/") ? "#FFF" : "none"}
            />
            <div className={cn("flex h-full items-center  py-[6px]")}>
              <span
                className={cn(
                  "text-xl uppercase leading-4 text-white decoration-2",
                  isNavItemActive("/") &&
                    "font-bold underline decoration-[#FFAF2E]",
                )}
              >
                {t(`itemsNav.students`)}
              </span>
            </div>
          </Link>
        </SheetClose>

        {/* <Separator className="!my-4 !bg-white !bg-opacity-35" />
        <SheetClose asChild>
          <Link
            className="flex items-center gap-2 px-4 py-[6px]"
            href="/corporates"
          >
            <Agreement01Icon
              size={28}
              variant={isNavItemActive("/corporates") ? "solid" : "stroke"}
              color="white"
            />
            <div className={cn("flex h-full items-center")}>
              <span
                className={cn(
                  "text-xl uppercase leading-4 text-white",
                  isNavItemActive("/corporates") &&
                    "font-bold underline decoration-[#FFAF2E] decoration-2",
                )}
              >
                CORPORATES
              </span>
            </div>
          </Link>
        </SheetClose> */}

        <Separator className="!my-4 !bg-white !bg-opacity-35" />
        <SheetClose asChild>
          <Link
            className="flex items-center gap-2 px-4 py-[6px]"
            href="/about-us"
          >
            <UserGroupIcon
              size={28}
              variant={isNavItemActive("/about-us") ? "solid" : "stroke"}
              color="white"
            />
            <div className={cn("flex h-full items-center")}>
              <span
                className={cn(
                  "text-xl uppercase leading-4 text-white",
                  isNavItemActive("/about-us") &&
                    "font-bold underline decoration-[#FFAF2E] decoration-2",
                )}
              >
                who we are
              </span>
            </div>
          </Link>
        </SheetClose>

        <Separator className="!my-4 !bg-white !bg-opacity-35" />
        <SheetClose asChild>
          <Link className="flex items-center gap-2 px-4 py-[6px]" href="/news">
            <NewsIcon
              size={28}
              variant={isNavItemActive("/news") ? "solid" : "stroke"}
              color="white"
            />
            <div
              className={cn(
                "flex h-full items-center ",
                isNavItemActive("/news") &&
                  "font-bold underline decoration-[#FFAF2E] decoration-2",
              )}
            >
              <span
                className={cn(
                  "text-xl uppercase leading-4 text-white",
                  pathName === "/news" ? "font-bold" : "",
                )}
              >
                NEWS
              </span>
            </div>
          </Link>
        </SheetClose>

        <Separator className="!my-4 !bg-white !bg-opacity-35" />
        {/* <div className="flex items-center gap-2 px-4">
          <EarthIcon size={28} color="white" />
          <LanguageSwitcherMobile />
        </div>
        <Separator className="!my-4 !bg-white !bg-opacity-35" /> */}
      </SheetContent>
    </Sheet>
  );
}
